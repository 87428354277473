/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { lazy, Suspense } from 'react';
import { Router as ReactRouter } from 'react-router';
import { Redirect, Route } from 'react-router-dom';

import Meta from 'Component/Meta';
import {
    PRINT_ALL_INVOICES,
    PRINT_ALL_REFUNDS,
    PRINT_ALL_SHIPMENT,
    PRINT_INVOICE,
    PRINT_ORDER as PRINT_ORDER_REQUEST,
    PRINT_REFUND,
    PRINT_SHIPMENT
} from 'Component/MyAccountOrderPrint/MyAccountOrderPrint.config';
import UrlRewrites from 'Route/UrlRewrites';
import {
    Breadcrumbs,
    CartPage,
    Checkout,
    CmsPage,
    ConfirmAccountPage,
    ContactPage,
    CookiePopup,
    CreateAccountPage,
    DemoNotice,
    Footer,
    ForgotPasswordPage,
    Header,
    HomePage,
    LoginAccountPage,
    MenuPage,
    MyAccount,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    OrderPrintPage,
    PasswordChangePage,
    Router as SourceRouter,
    SearchPage,
    SendConfirmationPage,
    SomethingWentWrong,
    StyleGuidePage,
    withStoreRegex
} from 'SourceComponent/Router/Router.component';
import {
    ADDRESS_BOOK, LOYALTY_PROGRAM,
    MY_DOCUMENTS,
    MY_DOWNLOADABLE, MY_ORDERS, NEWSLETTER_SUBSCRIPTION
} from 'Type/Account.type';
import { isSignedIn } from 'Util/Auth';
import history from 'Util/History';

import {
    ACCOUNT_FORGOT_PASSWORD,
    BEFORE_ITEMS_TYPE,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    CONFIRM_ACCOUNT,
    CONTACT_PAGE,
    CREATE_ACCOUNT,
    HOME,
    LOGIN,
    LOGIN_AS_CUSTOMER,
    MENU,
    MY_ACCOUNT,
    MY_ACCOUNT_ADDRESS,
    MY_ACCOUNT_DOCUMENTS,
    MY_ACCOUNT_DOWNLOADABLE,
    MY_ACCOUNT_LOYALTY_PROGRAM,
    MY_ACCOUNT_MENU_PAGE,
    MY_ACCOUNT_NEWSLETTER,
    MY_ACCOUNT_ORDER,
    MY_ACCOUNT_ORDERS,
    PRINT_ORDER,
    SEARCH,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES
} from './Router.config';

import './Router.override.style';

export {
    CartPage,
    Checkout,
    CmsPage,
    CookiePopup,
    DemoNotice,
    Header,
    HomePage,
    MyAccount,
    PasswordChangePage,
    SearchPage,
    SendConfirmationPage,
    ConfirmAccountPage,
    MenuPage,
    Footer,
    NavigationTabs,
    NewVersionPopup,
    NotificationList,
    OfflineNotice,
    ContactPage,
    CreateAccountPage,
    LoginAccountPage,
    ForgotPasswordPage,
    SomethingWentWrong,
    StyleGuidePage,
    Breadcrumbs,
    OrderPrintPage,
    withStoreRegex
};
export const MyAccountMenuPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account-menu-page" */ 'Route/MyAccountMenuPage'));

export const LoginAsCustomer = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/LoginAsCustomer'));

export const PostPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "post-page" */ 'Route/PostPage'));

export const BlogPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "TrustMateLemurWidget" */ 'Route/BlogPage'));

export const WindowNavigation = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "TrustMateLemurWidget" */ 'Component/WindowNavigation'));

/** @namespace Satisfly/Component/Router/Component/CheckAuth */

// eslint-disable-next-line react/prop-types
export const CheckAuth = ({ render: Component, ...rest }) => (
    <Route
      { ...rest }
      render={ (props) => (
          isSignedIn() === true
              ? <Component { ...props } />
              : <Redirect to="/customer/account/login/" />
      ) }
    />
);

/** @namespace Satisfly/Component/Router/Component */
export class Router extends SourceRouter {
    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        {
            component: <Route path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <CheckAuth path={ withStoreRegex('/cart') } exact render={ CartPage } />,
            position: 50,
            name: CART
        },
        {
            component: <CheckAuth path={ withStoreRegex('/checkout/:step?') } render={ Checkout } />,
            position: 55,
            name: CHECKOUT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61,
            name: CREATE_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62,
            name: LOGIN
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirmation') } render={ (props) => <SendConfirmationPage { ...props } /> } />,
            position: 64,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/loginascustomer/login/index') } render={ (props) => <LoginAsCustomer { ...props } /> } />,
            position: 66,
            name: LOGIN_AS_CUSTOMER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/view/order_id/:orderId?') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 70,
            name: MY_ACCOUNT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/history') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_ORDERS } /> } />,
            position: 71,
            name: MY_ACCOUNT_ORDERS
        },
        {
            component: <Route path={ withStoreRegex('/downloadable/customer/products') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOWNLOADABLE } /> } />,
            position: 72,
            name: MY_ACCOUNT_DOWNLOADABLE
        },
        {
            component: <Route path={ withStoreRegex('/customer/address') } render={ (props) => <MyAccount { ...props } selectedTab={ ADDRESS_BOOK } /> } />,
            position: 74,
            name: MY_ACCOUNT_ADDRESS
        },
        {
            component: <Route path={ withStoreRegex('/newsletter/manage') } render={ (props) => <MyAccount { ...props } selectedTab={ NEWSLETTER_SUBSCRIPTION } /> } />,
            position: 75,
            name: MY_ACCOUNT_NEWSLETTER
        },
        {
            component: <Route path={ withStoreRegex('/customer/account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 76,
            name: MY_ACCOUNT
        },
        {
            component: <Route path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80,
            name: MENU
        },
        {
            component: <Route path={ withStoreRegex('/contact') } render={ (props) => <ContactPage { ...props } /> } />,
            position: 82,
            name: CONTACT_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/blog') } exact render={ (props) => <BlogPage { ...props } /> } />,
            position: 82
        },
        {
            component: <Route path={ withStoreRegex('/blog/:post') } exact render={ (props) => <PostPage { ...props } /> } />,
            position: 82
        },
        {
            component: <Route path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 84,
            name: STYLE_GUIDE
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/print/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ORDER_REQUEST } /> } />,
            position: 90,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_INVOICES } /> } />,
            position: 91,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_SHIPMENT } /> } />,
            position: 92,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/order_id/:orderId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_ALL_REFUNDS } /> } />,
            position: 93,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printInvoice/invoice_id/:invoiceId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_INVOICE } /> } />,
            position: 94,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printShipment/shipment_id/:shipmentId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_SHIPMENT } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/sales/order/printCreditmemo/creditmemo_id/:refundId?') } render={ (props) => <OrderPrintPage { ...props } orderPrintRequest={ PRINT_REFUND } /> } />,
            position: 95,
            name: PRINT_ORDER
        },
        {
            component: <Route path={ withStoreRegex('/documents') } render={ (props) => <MyAccount { ...props } selectedTab={ MY_DOCUMENTS } /> } />,
            position: 96,
            name: MY_ACCOUNT_DOCUMENTS
        },
        {
            component: <CheckAuth path={ withStoreRegex('/account-menu') } render={ MyAccountMenuPage } />,
            position: 97,
            name: MY_ACCOUNT_MENU_PAGE
        },
        {
            component: <Route path={ withStoreRegex('/loyalty-program') } render={ (props) => <MyAccount { ...props } selectedTab={ LOYALTY_PROGRAM } /> } />,
            position: 98,
            name: MY_ACCOUNT_LOYALTY_PROGRAM
        },
        {
            component: <Route render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        }
    ];

    render() {
        return (
            <>
                <Meta />
                <ReactRouter history={ history }>
                    { this.renderSectionOfType(BEFORE_ITEMS_TYPE) }
                    <Suspense fallback={ this.renderFallbackPage(true) }>
                        <WindowNavigation />
                        { this.renderRouterContent() }
                    </Suspense>
                </ReactRouter>
            </>
        );
    }
}

export default Router;
