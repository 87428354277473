/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import { Field } from 'Util/Query';

/** @namespace Satisfly/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    getCreateAccountMutation(options) {
        const { customer } = options;

        return new Field('createCustomerV2')
            .addArgument('input', 'CustomerCreateInput!', customer)
            .addField(this._getCustomerField());
    }

    _getAddressFields() {
        return [
            'id',
            'customer_id',
            'country_id',
            'street',
            'telephone',
            'postcode',
            'city',
            'firstname',
            'lastname',
            'middlename',
            'prefix',
            'suffix',
            'default_shipping',
            'default_billing',
            'vat_id',
            'company',
            this._getRegionField()
        ];
    }

    getCustomerLoyaltyQuery() {
        return new Field('customer')
            .addField(
                new Field('mp_loyalty_program_dashboard')
                    .addField(this._getBenefitField())
                    .addField(this._getStatisticField())

            );
    }

    _getBenefitField() {
        return new Field('benefit');
    }

    _getStatisticField() {
        return new Field('statistics')
            .addFieldList(this._getStatisticFields());
    }

    _getStatisticFields() {
        return [
            'description',
            'is_enable_email_notify',
            'program_name',
            'to',
            this._getColsField(),
            this._getRowsField()
        ];
    }

    _getColsField() {
        return new Field('cols')
            .addFieldList([
                'duration',
                'end',
                'name',
                'start'
            ]);
    }

    _getRowsField() {
        return new Field('rows')
            .addField(new Field('row')
                .addFieldList([
                    'name',
                    'value'
                ]));
    }

    getSaveIsEnableEmailNotify(status) {
        return new Field('MpLoyaltyProgramNotification')
            .addArgument('status', 'Boolean!', status);
    }
}

export default new MyAccountQuery();
