/* eslint-disable no-magic-numbers */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PAGE_VISIT_DEBOUNCE_DELAY, PREVIOUS_ROUTE } from 'Component/NavigationAbstract/NavigationAbstract.config';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { App as SourceApp } from 'SourceComponent/App/App.component';
import BrowserDatabase from 'Util/BrowserDatabase';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import {
    checkForCacheUpdates, debounce, executeGet
} from 'Util/Request';
import getStore from 'Util/Store';

/** @namespace Satisfly/Component/App/Component */
export class App extends SourceApp {
    state = {
        isSomethingWentWrong: false,
        errorDetails: {},
        actualPathname: ''
    };

    componentDidMount() {
        history.listen(debounce((next) => {
            const { actualPathname = '' } = this.state;

            if (actualPathname !== next.pathname) {
                BrowserDatabase.setItem(actualPathname, PREVIOUS_ROUTE);
                this.registerPageVisit(next);
            }
        }, PAGE_VISIT_DEBOUNCE_DELAY));

        checkForCacheUpdates();

        this._requestCustomCSSBlock();
    }

    componentWillUnmount() {
        BrowserDatabase.deleteItem(PREVIOUS_ROUTE);
    }

    registerPageVisit(next) {
        const { SR } = window;
        SR.event.pageVisit()
            .then(
                /** @namespace Satisfly/Component/App/Component/App/registerPageVisit/pageVisit/then */
                () => {
                    SR.dynamicContent.get();
                    this.setState({ actualPathname: next.pathname });
                }
            );
    }

    _requestCustomCSSBlock() {
        const storeCode = getStore().getState()?.ConfigReducer?.code;

        const cmsIdentifier = `custom_css_${storeCode}`;

        const query = prepareQuery([CmsBlockQuery.getQuery({
            identifiers: [cmsIdentifier]
        })]);

        executeGet(query, 'CmsBlock', 300).then(
            /** @namespace Satisfly/Component/App/Component/App/_requestCustomCSSBlock/then/catch/executeGet/then */
            ({ cmsBlocks }) => {
                if (!cmsBlocks || !cmsBlocks.items || cmsBlocks.items.length === 0) {
                    return;
                }

                const cmsContent = cmsBlocks.items[0]?.content || '';
                this._injectCustomCSS(cmsContent);
            }
        ).catch(
            /** @namespace Satisfly/Component/App/Component/App/_requestCustomCSSBlock/then/catch */
            (error) => console.error('Error fetching CMS Block:', error)
        );
    }

    _injectCustomCSS(content) {
        const styleMatch = content.match(/<style[^>]*>([\s\S]*?)<\/style>/i);

        if (!styleMatch || styleMatch.length < 2) {
            return;
        }

        const customCSS = styleMatch[1].trim();

        let styleElement = document.getElementById('custom-cms-style');

        if (!styleElement) {
            styleElement = document.createElement('style');
            styleElement.id = 'custom-cms-style';
            document.head.appendChild(styleElement);
        }

        styleElement.innerHTML = customCSS;
    }
}

export default App;
